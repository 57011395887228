<template>
  <div class="app-wrap">
    <h3>
      <i
        class="iconfont icon-a-fundmarkect-back"
        @click="$router.go(-1)"
      />
      安全中心
    </h3>
    <zh-step
      :index="index"
      :steps="['验证证件', '验证手机', '重置交易密码', '操作成功']"
    />
    <template v-if="index === 1">
      <div class="step1">
        <h4>验证证件</h4>
        <p>需确认为本人证件</p>
        <el-form
          ref="step1"
          :model="step1"
          :rules="step1Rules"
          style="margin-top:62px"
          label-position="top"
        >
          <el-form-item
            style="textAlign: center"
            prop="clientName"
          >
            <el-input
              v-model="step1.clientName"
              placeholder="输入姓名"
              class="pw-inpput"
            />
          </el-form-item>
          <el-form-item
            style="textAlign: left;"
            class="card-item"
            label="请输入本人证件"
            prop="idNo"
          >
            <el-input
              v-model="step1.idNo"
              class="input-with-select pw-inpput"
              placeholder="输入证件号"
            >
              <el-select
                slot="prepend"
                v-model="idcardType"
              >
                <el-option
                  v-for="item in idCardDics"
                  :key="item.idKindGb"
                  :label="item.text"
                  :value="item.idKindGb"
                />
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item
            label="图形验证码"
            class="card-item"
            style="margin-bottom: 40px"
            prop="imgCode"
          >
            <el-input
              v-model="step1.imgCode"
              style="width:250px !important"
              class="pw-inpput"
              placeholder="请输入图片中的数字"
            />
            <img
              class="img-code"
              :src="imgCodeInfo.url"
              @click="getImgCode"
            >
          </el-form-item>
          <el-form-item style="textAlign: center">
            <span
              class="sub-btn"
              @click="handleGoStep2"
            >下一步</span>
          </el-form-item>
        </el-form>
      </div>
    </template>
    <template v-if="index === 2">
      <div class="step1">
        <h4>验证手机</h4>
        <p>验证绑定手机</p>
        <el-form
          ref="step2"
          :model="step2"
          :rules="step2Rules"
          style="margin-top:62px"
          label-position="top"
        >
          <el-form-item
            label="请输入绑定手机号"
            class="card-item"
            prop="mobileTel"
          >
            <el-input
              v-model="step2.mobileTel"
              placeholder="输入手机号"
              class="pw-inpput input-with-select"
            >
              <el-select
                slot="prepend"
                v-model="phoneType"
                class="phoneType-select"
              >
                <el-option
                  label="中国(+86)"
                  value="0"
                />
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item
            :label="'点击获取验证码，短信将发送至安全手机*******'+ step2.mobileTel.slice(-3)"
            class="card-item"
            prop="smsCode"
          >
            <el-input
              v-model="step2.smsCode"
              placeholder="请输入验证码"
              class="pw-inpput"
              style="width: 270px !important"
            />
            <span
              class="code-b"
              :class="sendCodeDisabled ? 'disabled' : ''"
              @click="sendCode('sendCodeDisabled', 'sendCodeText')"
            >{{ sendCodeText }}</span>
          </el-form-item>
          <el-form-item style="textAlign: center">
            <span
              class="sub-btn"
              @click="handleGoStep3"
            >下一步</span>
          </el-form-item>
        </el-form>
      </div>
    </template>
    <template v-if="index === 3">
      <div class="pw-t">
        <h3>重置交易密码</h3>
        <p>6位不连续且不重复的数字</p>
      </div>
      <div class="step2">
        <el-form
          ref="step3"
          :model="step3"
          :rules="step3Rules"
          style="margin-top:62px"
        >
          <el-form-item prop="passWord" style="textAlign: center">
            <el-input
              v-model="step3.passWord"
              :type="pw1 ? 'text' : 'password'"
              class="pw-inpput"
              placeholder="输入新密码"
            >
              <i
                slot="suffix"
                class="iconfont"
                :class="pw1 ? 'icon-home-eyes-open' : 'icon-home-eyes-close'"
                @click="pw1 = !pw1"
              />
            </el-input>
          </el-form-item>
          <el-form-item prop="againPassWord" style="textAlign: center">
            <el-input
              v-model="step3.againPassWord"
              :type="pw2 ? 'text' : 'password'"
              class="pw-inpput"
              placeholder="再次输入新密码"
            >
              <i
                slot="suffix"
                class="iconfont"
                :class="pw2 ? 'icon-home-eyes-open' : 'icon-home-eyes-close'"
                @click="pw2 = !pw2"
              />
            </el-input>
          </el-form-item>
          <el-form-item style="textAlign: center">
            <span
              class="sub-btn"
              @click="handleGoStep4"
            >下一步</span>
          </el-form-item>
        </el-form>
      </div>
    </template>
    <template v-if="index === 4">
      <div class="suc-w">
        <img src="@/assets/images/myFavors/susess.png">
        <p>交易密码重置成功！</p>
        <span
          class="suc-btn"
          @click="$router.go(-1)"
        >返回安全中心</span>
      </div>
    </template>
  </div>
</template>

<script>
import { idCardCheck, phoneCheck, tradPWCheck1, tradPWCheck2 } from '@/utils/checkReg.js'
import { inspection, sendcode, checkVerifCode, resetTradePassword  } from "@/api/bankmanagement"
import { graphicVerifCode } from '@/api/commom'
  export default {
    data() {
      const validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入登录密码'));
        } else {
          if (this.step3.againPassWord !== '') {
            this.$refs.step3.validateField('againPassWord');
          }
          callback();
        }
      };
      const validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入登录密码'));
        } else if (value !== this.step3.passWord) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };


      return {
        pw1: false,
        pw2: false,
        index: 1,
        phoneType: '0',
        

        userInfo: {},
        sendCodeDisabled: false,
        sendCodeText: '获取验证码',


        step1: {
          clientName: '',
          idNo: '',
          imgCode: ''
        },
        step1Rules: {
          clientName: [
            { required: true, message: '请输入姓名', trigger: 'blur' },
          ],
          idNo: [
            { required: true, message: '请输入证件号码', trigger: 'blur' },
            { pattern: idCardCheck, message: '证件号码格式不正确' },
          ],
          imgCode: [
            { required: true, message: '请输入图形验证码', trigger: 'blur' },
          ]
        },
        idcardType: '0',
        idCardDics: [
          {
						idKindGb: '0',
						text: '身份证'
					}
        ],

        step2: {
          mobileTel: '',
          smsCode: '',
        },
        step2Rules: {
          mobileTel: [
            { required: true, message: '请输入绑定手机号', trigger: 'blur' },
            { pattern: phoneCheck, message: '手机号码格式不正确' }
          ],
          smsCode: [
            { required: true, message: '请输入短验证码', trigger: 'blur' },
          ]
        },

        step3: {
          passWord: '',
          againPassWord: '',
        },
        step3Rules: {
          passWord: [
            { validator: validatePass, trigger: 'change' },
            { required: true, message: '请输入交易密码', trigger: 'blur' },
            { pattern: tradPWCheck1, message: '密码格式不正确' },
            { pattern: tradPWCheck2, message: '密码格式不正确' },
          ],
          againPassWord: [
            { validator: validatePass2, trigger: 'change' },
            { required: true, message: '请再次输入交易密码', trigger: 'blur' },
            { pattern: tradPWCheck1, message: '密码格式不正确' },
            { pattern: tradPWCheck2, message: '密码格式不正确' },
          ],
        },

        imgCodeInfo: {},
        checkUserUUid: '', // 校验用户的uuid
        
        
        smsUUID: '', // 校验手机号后的uuid
      }
    },
    created() {
      this.getImgCode()
      this.userInfo = JSON.parse(localStorage.getItem('customerInfo')) || {}
      this.step2.mobileTel = this.userInfo.mobileTel
    },
    methods: {
      sendCode(disabled, text)  {
        if (this[disabled]) {
          return
        }
        sendcode({
          phone: this.step2.mobileTel,
          verifCode: '5',
          uuid: this.checkUserUUid
        }).then(() => {
          this[disabled] = true
          this.countDown(disabled, text)
        })
      },
      countDown(disabled, text) {
        let num = 60
        this[text] = `${num}s后重新获取`
        const timer = setInterval(() => {
          console.log(num)
          num--
          if (num < 1) {
            clearInterval(timer)
            this[text] = `获取验证码`
            this[disabled] = false
          } else {
            this[text] = `${num}s后重新获取`
          }
        }, 1000);
        this.$once('hook:destroyed', () => {
          clearInterval(timer)
        })
      },
      getImgCode() {
        graphicVerifCode().then(res => {
          this.imgCodeInfo = res.data
        })
      },
      handleGoStep2() {
        this.$refs['step1'].validate((valid) => {
          if (valid) {
            if (this.step1.imgCode !== this.imgCodeInfo.code) {
              this.$message({
                message: '图形验证码不正确',
                type: 'warning'
              });
              return
            }
            inspection({
              idKindGb: this.idcardType,
              clientName: this.step1.clientName,
              idNo: this.step1.idNo
            }).then(res => {
              this.checkUserUUid = res.data
              this.index = 2
            })
          }
        })
        
      },
      handleGoStep3() {
        this.$refs['step2'].validate((valid) => {
          if (valid) {
            checkVerifCode({
              code: this.step2.smsCode,
              phone: this.step2.mobileTel,
              verifCode: "5"
            }).then(res => {
              this.smsUUID = res.data
              this.index = 3
            })
          }
        })
      },
      handleGoStep4() {
        this.$refs['step3'].validate((valid) => {
          if (valid) {
            resetTradePassword({
              mobileTel: this.step2.mobileTel,
              tradePassword: this.step3.passWord,
              tradePasswordCopy: this.step3.againPassWord,
              uuid: this.smsUUID
            }).then(() => {
              this.index = 4
            })
          }
        })
        
      }
    },
  }
</script>

<style lang="less" scoped>
@import url('@/assets/css/zc-common.less');
.app-wrap > h3 {
  .flex(flex-start, center, row);
  font-size: 20px;
  color: #1F1F1F;
  padding-bottom: 30px;
  border-bottom: 1px solid #EDF1F7;
  font-weight: normal;
  i {
    font-size:18px;
    color: #BDC0CB;
    margin-right: 14px;
  }
}
.pw-t {
  width: 100%;
  margin-top: 40px;
  &>h3 {
    font-weight: normal;
    font-size: 24px;
    color: #25293D;
    text-align: center;
  }
  &>p {
    margin-top: 22px;
    font-size: 12px;
    color: #BDC0CB;
    text-align: center;
  }
}

.sub-btn {
  .btn(450px, 50px);
  .inline-flex(center, center, row);
}

.suc-w {
  width: 100%;
  .flex(center, center, column);
  margin-top: 44px;
  &>img {
    width: 120px;
    height: 120px;
  }
  p {
    width: 100%;
    font-weight: bold;
    font-size: 18px;
    color: #25293D;
    text-align: center;
    margin-top: 54px;
  }
  .suc-btn {
    margin-top: 40px;
    .inline-flex(center, center, row);
    width: 350px;
    height: 50px;
    background: linear-gradient(109.88deg, #F7D9B7 -3.02%, #EABF96 98.27%);
    border-radius: 4px;
    font-size: 16px;
    color: #B88141;
    cursor: pointer;
  }
}
.step1 {
  margin-top: 44px;
  width: 100%;
  .flex(flex-start, center, column);
  &>h4 {
    font-weight: normal;
    font-size: 24px;
    color: #25293D;
  }
  &>p{
    font-size: 14px;
    color: #BDC0CB;
    margin-top: 22px;
  }
}
.step2 {
  .flex(center,center,column)
}
.img-code {
  width: 170px;
  height: 50px;
  border: 1px solid #E0E2E8;
  border-radius: 2px;
  margin-left: 30px;
}
.code-b {
  .inline-flex(center, center, row);
  width: 155px;
  height: 50px;
  border: 0.6px solid #CE9B63;
  border-radius: 4px;
  color: #CE9B63;
  font-size: 18px;
  margin-left: 24px;
  cursor: pointer;
  &.disabled {
    border: 0.6px solid #BDC0CB;
    color: #BDC0CB;
  }
}
</style>
<style>
.pw-inpput {
  width:450px !important;
  height:50px !important
}
.pw-inpput .el-input__inner {
  height:50px !important;
  line-height: 50px !important;
}
.pw-inpput .el-input__suffix {
  right: 20px !important;
  display: flex;
  align-items: center;
}
.input-with-select .el-input-group__prepend {
  width: 65px !important;
}
.card-item .el-form-item__label{
  font-size: 14px !important;
  color: #BDC0CB !important;
  padding: 0 !important;
}
.card-item .el-form-item__content {
  display: flex !important;
}
.phoneType-select .el-input__inner {
  padding-left: 10px !important;
  padding-right: 0 !important;
}
.phoneType-select .el-input--suffix .el-input__suffix {
    right: 10px !important;
}
</style>